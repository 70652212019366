#root {
  width: 100%;
  height: 100%;
}

.ant-form-item-required::before {
  display: none;
}

.ant-form-item-required:after {
  content: 'xxx';
}
