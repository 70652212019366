.table-header-style {
  margin: 12px 16px;
  background-color: #fff;
  .ant-card-head {
    padding-left: 16px;
    padding-right: 16px;
  }
  .ant-card-body {
    padding: 0;
  }
}
